@font-face {
  font-family: "Trovacantieri";
  src: url("../assets/fonts/trovacantieri/Trovacantieri.eot");
  src: url("../assets/fonts/trovacantieri/Trovacantieri.eot?#iefix") format("embedded-opentype"),
       url("../assets/fonts/trovacantieri/Trovacantieri.woff2") format("woff2"),
       url("../assets/fonts/trovacantieri/Trovacantieri.woff") format("woff"),
       url("../assets/fonts/trovacantieri/Trovacantieri.ttf") format("truetype"),
       url("../assets/fonts/trovacantieri/Trovacantieri.svg#Trovacantieri") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Trovacantieri";
    src: url("../assets/fonts/trovacantieri/Trovacantieri.svg#Trovacantieri") format("svg");
  }
}

.tc:before{
  display: inline-block;
  font-family: "Trovacantieri";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.tc:before { content: "\f100"; }
.tc-euro:before { content: "\f101"; }
.tc-bathroom:before { content: "\f102"; }
.tc-bed:before { content: "\f103"; }
.tc-area:before { content: "\f104"; }
.tc-right-chevron:before { content: "\f105"; }
.tc-left-chevron:before { content: "\f105"; transform: rotate(180deg) }
.tc-file:before { content: "\f106"; }
.tc-email:before { content: "\f107"; }
.tc-world-wide-web:before { content: "\f108"; }
.tc-bell:before { content: "\f109"; }
.tc-menu:before { content: "\f10a"; }
.tc-check:before { content: "\f10b"; }
.tc-printer:before { content: "\f10c"; }
.tc-alert:before { content: "\f10d"; }
    
$font-Trovacantieri-search: "\f100";
$font-Trovacantieri-euro: "\f101";
$font-Trovacantieri-bathroom: "\f102";
$font-Trovacantieri-bed: "\f103";
$font-Trovacantieri-area: "\f104";
$font-Trovacantieri-right-chevron: "\f105";
$font-Trovacantieri-file: "\f106";
$font-Trovacantieri-email: "\f107";
$font-Trovacantieri-world-wide-web: "\f108";
$font-Trovacantieri-bell: "\f109";
$font-Trovacantieri-menu: "\f10a";
$font-Trovacantieri-check: "\f10b";
$font-Trovacantieri-check: "\f10b";
$font-Trovacantieri-check: "\f10b";
$font-Trovacantieri-printer: "\f10c";
$font-Trovacantieri-alert: "\f10d";

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// @font-face {
//   font-family: "Karla";
//   src: url("../assets/fonts/karla/Karla-Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Karla";
//   src: url("../assets/fonts/karla/Karla-Italic.ttf") format("truetype");
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Karla";
//   src: url("../assets/fonts/karla/Karla-Bold.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Karla";
//   src: url("../assets/fonts/karla/Karla-BoldItalic.ttf") format("truetype");
//   font-weight: bold;
//   font-style: italic;
// }

// .text-karla {
//   font-family: "Rubik";
// }