$btn-size: 40px;
.leaflet-control-layers {
  border: none!important;
  border-radius: 8px;
  .leaflet-control-layers-toggle {
    width: $btn-size!important;
    height: $btn-size!important;
  }
}
.leaflet-bar, .leaflet-control-layers {
  box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px !important;
}
.leaflet-bar {
  border: none!important;
  a {
    width: $btn-size!important;
    height: $btn-size!important;
    line-height: $btn-size!important;
  }
  a:first-child {
    border-top-left-radius: 8px!important;
    border-top-right-radius: 8px!important;
  }
  a:last-child {
    border-bottom-left-radius: 8px!important;
    border-bottom-right-radius: 8px!important;
  }
}
.leaflet-headerbar {
  padding: 0 10px;
}
.gm-style-iw, .leaflet-popup, .map-marker-popup{
  margin-bottom: 42px;
  .gm-style-iw-d, .leaflet-popup-content-wrapper, .map-marker-popup-container {
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    > div, .leaflet-popup-content, .map-marker-popup-content {
      margin: 0;
      width: 450px!important;
    }
    .popup-image-container {
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .popup-info, .leaflet-popup-tip {
      color: white;
      //background-color: transparent;
      background-color: rgba(45%, 45%, 45%, .9);
    }
    .popup-description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .leaflet-popup-tip-container {
    width: 56px!important;
    height: 35px!important;
    margin-left: -28px!important;
    .leaflet-popup-tip {
      height: 30px;
      width: 30px;
      background-color: rgba(45%, 45%, 45%, .9);
      margin-top: -15px;
    }
  }
}

.gm-style-iw {
  overflow: unset!important;
  background-color: transparent!important;
  border-radius: 0!important;
  padding: 0!important;
  .gm-style-iw-d {
    background-color: transparent!important;
    overflow: unset!important;
    max-height: none!important;
    &::after {
      content:'';
      display:block;
      width:0;
      height:0;
      position:absolute;
      z-index: 1;

      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      border-top: 20px solid rgba(45%, 45%, 45%, .9);

      bottom: -19px;
      left: calc(50% - 20px);
    }
  }
}

.gm-style-iw-t {
  bottom: 58px!important;
  &::after {
    display: none;
  }
}