[v-cloak] {
  display: none;
}

body {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
}

@media print{
  @media print {
    @page {
      size: 475mm 330mm;
      margin: 14mm;
    }
    .construction {
      max-width: 100% !important;
    }
  }
}
.construction {
  max-width: 1150px;
  .q-carousel .q-carousel__slide {
    padding: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.text-brand {
  color: #EB6909;
}
.bg-brand {
  background: #EB6909;
}

.text-darkgray {
  color: $darkgray;
}
.bg-darkgray {
  background: $darkgray;
}

.font-weight-medium {
  font-weight: 600!important;
}

.q-btn {
  &:not(.q-btn--rounded) {
    border-radius: 0!important;
  }
  &.nowrap .q-btn__content {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

.q-carousel__slide.contain {
  -webkit-background-size: contain!important;
  background-size: contain!important;
  background-repeat: no-repeat;
}

.bordered-container {
  border: 2px solid #EFEFEF
}

.no-decoration {
  text-decoration: none;
}

.text-light-gray {
  color: #EFEFEF;
}
.bg-light-gray {
  background: #EFEFEF;
}

.home-page {
  .map {
    height: 700px!important;
  }
}

.home-cover {
  height: 510px;
  width: 100%;
  object-fit: cover;
  object-position: 50% 60%;
}
.title {
  line-height: 1.3;
}
.advantages-container {
  background-color: #FAFAFA;
  .advantages {
    @media (min-width: 1023px) {
      width: 80%
    }
  }
  img {
    object-fit: contain;
    width: 95px;
  }
  p {
    text-align: center;
  }
}

.home-constructions {

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .home-construction.container {
    cursor: pointer;
    img {
      transition: all .1s ease-in-out;
    }
    &:hover img {
      transform: scale(1.1);
    }
  }
}

.results-container {

  height: 100%!important;
  width: 440px !important;
  overflow: auto;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
  .result {
    cursor: pointer;
    .result-caption {
      border: 1px solid #313131;
      border-top: none
    }
    .image-container {
      overflow: hidden;
      height: 200px;

      img {
        object-fit: cover;
        transition: all .1s ease-in-out;
      }
      &:hover img {
        transform: scale(1.1);
      }
    }
  }
}

.image-overlay {
  position: absolute;
  background-color: rgba(81, 81, 81, 0.8);
  &.bottom {
    bottom: 0;
  }
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  &.top {
    top: 0;
  }
}

.sticky-position {
  position: sticky;
  z-index: 1;
  &.sticky-top {
     top: 0
  }
  &.sticky-bottom {
     bottom: 0
  }
}

.border-black {
  border: 1px solid #000000;
}

.construction-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #EB6909;
  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    pointer-events: none;
    color: $gray-500;
  }
}
.swiper-container {
   width: 100vw!important;
   margin-bottom: 70px;
  .swiper-wrapper {
    will-change: transform;
    transition-property: transform!important;
  }
}
.swiper-slide {
  background-color: white;
  width: 85% !important;
  pointer-events: auto;
  overflow: hidden;
  &.construction-slide {
    border-radius: 10px;
    display: flex;
    .images-container {
      .image-slide {
        height: 94px;
        width: 94px;
        object-fit: cover;
        border-radius: 0;
        //width: 75%!important;
      }
      img {
      }
    }
    .slide-info {
      min-width: 0;
      padding: 8px 12px;
      .description {
        line-height: 1.2;
        height: 2.2em;
        overflow: hidden;
      }
    }
  }
}

.hoverable {
  .hover-only {
    display: block;
  }
  &:hover {
    .hover-only {
      display: block;
    }
  }
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darken(#dbdbdb, 30%);
  border-radius: 3px;
  background-clip: padding-box;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #dbdbdb;
  border-radius: 3px;
  background-clip: padding-box;
}