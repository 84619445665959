@import url(~leaflet/dist/leaflet.css);
@import url(~leaflet.markercluster/dist/MarkerCluster.css);
@import url(~leaflet.markercluster/dist/MarkerCluster.Default.css);
@import url(~line-awesome/dist/line-awesome/css/line-awesome.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
h4 {
  font-size: 2rem; }

h6,
.text-h6 {
  font-size: 1.1rem; }

@font-face {
  font-family: "Trovacantieri";
  src: url("../assets/fonts/trovacantieri/Trovacantieri.eot");
  src: url("../assets/fonts/trovacantieri/Trovacantieri.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/trovacantieri/Trovacantieri.woff2") format("woff2"), url("../assets/fonts/trovacantieri/Trovacantieri.woff") format("woff"), url("../assets/fonts/trovacantieri/Trovacantieri.ttf") format("truetype"), url("../assets/fonts/trovacantieri/Trovacantieri.svg#Trovacantieri") format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Trovacantieri";
    src: url("../assets/fonts/trovacantieri/Trovacantieri.svg#Trovacantieri") format("svg"); } }

.tc:before {
  display: inline-block;
  font-family: "Trovacantieri";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

.tc:before {
  content: "\f100"; }

.tc-euro:before {
  content: "\f101"; }

.tc-bathroom:before {
  content: "\f102"; }

.tc-bed:before {
  content: "\f103"; }

.tc-area:before {
  content: "\f104"; }

.tc-right-chevron:before {
  content: "\f105"; }

.tc-left-chevron:before {
  content: "\f105";
  transform: rotate(180deg); }

.tc-file:before {
  content: "\f106"; }

.tc-email:before {
  content: "\f107"; }

.tc-world-wide-web:before {
  content: "\f108"; }

.tc-bell:before {
  content: "\f109"; }

.tc-menu:before {
  content: "\f10a"; }

.tc-check:before {
  content: "\f10b"; }

.tc-printer:before {
  content: "\f10c"; }

.tc-alert:before {
  content: "\f10d"; }

[v-cloak] {
  display: none; }

body {
  font-family: 'Rubik', sans-serif;
  font-size: 16px; }

@media print {
  @page {
    size: 475mm 330mm;
    margin: 14mm; }
  .construction {
    max-width: 100% !important; } }

.construction {
  max-width: 1150px; }
  .construction .q-carousel .q-carousel__slide {
    padding: 0; }
    .construction .q-carousel .q-carousel__slide img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.text-brand {
  color: #EB6909; }

.bg-brand {
  background: #EB6909; }

.text-darkgray {
  color: #333333; }

.bg-darkgray {
  background: #333333; }

.font-weight-medium {
  font-weight: 600 !important; }

.q-btn:not(.q-btn--rounded) {
  border-radius: 0 !important; }

.q-btn.nowrap .q-btn__content {
  flex-wrap: nowrap;
  white-space: nowrap; }

.q-carousel__slide.contain {
  -webkit-background-size: contain !important;
  background-size: contain !important;
  background-repeat: no-repeat; }

.bordered-container {
  border: 2px solid #EFEFEF; }

.no-decoration {
  text-decoration: none; }

.text-light-gray {
  color: #EFEFEF; }

.bg-light-gray {
  background: #EFEFEF; }

.home-page .map {
  height: 700px !important; }

.home-cover {
  height: 510px;
  width: 100%;
  object-fit: cover;
  object-position: 50% 60%; }

.title {
  line-height: 1.3; }

.advantages-container {
  background-color: #FAFAFA; }
  @media (min-width: 1023px) {
    .advantages-container .advantages {
      width: 80%; } }
  .advantages-container img {
    object-fit: contain;
    width: 95px; }
  .advantages-container p {
    text-align: center; }

.home-constructions {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }
  .home-constructions::-webkit-scrollbar {
    display: none; }
  .home-constructions .home-construction.container {
    cursor: pointer; }
    .home-constructions .home-construction.container img {
      transition: all .1s ease-in-out; }
    .home-constructions .home-construction.container:hover img {
      transform: scale(1.1); }

.results-container {
  height: 100% !important;
  width: 440px !important;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }
  .results-container::-webkit-scrollbar {
    display: none; }
  .results-container .result {
    cursor: pointer; }
    .results-container .result .result-caption {
      border: 1px solid #313131;
      border-top: none; }
    .results-container .result .image-container {
      overflow: hidden;
      height: 200px; }
      .results-container .result .image-container img {
        object-fit: cover;
        transition: all .1s ease-in-out; }
      .results-container .result .image-container:hover img {
        transform: scale(1.1); }

.image-overlay {
  position: absolute;
  background-color: rgba(81, 81, 81, 0.8); }
  .image-overlay.bottom {
    bottom: 0; }
  .image-overlay.left {
    left: 0; }
  .image-overlay.right {
    right: 0; }
  .image-overlay.top {
    top: 0; }

.sticky-position {
  position: sticky;
  z-index: 1; }
  .sticky-position.sticky-top {
    top: 0; }
  .sticky-position.sticky-bottom {
    bottom: 0; }

.border-black {
  border: 1px solid #000000; }

.construction-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #EB6909; }
  .construction-link:hover {
    cursor: pointer; }
  .construction-link[disabled] {
    pointer-events: none;
    color: #adb5bd; }

.swiper-container {
  width: 100vw !important;
  margin-bottom: 70px; }
  .swiper-container .swiper-wrapper {
    will-change: transform;
    transition-property: transform !important; }

.swiper-slide {
  background-color: white;
  width: 85% !important;
  pointer-events: auto;
  overflow: hidden; }
  .swiper-slide.construction-slide {
    border-radius: 10px;
    display: flex; }
    .swiper-slide.construction-slide .images-container .image-slide {
      height: 94px;
      width: 94px;
      object-fit: cover;
      border-radius: 0; }
    .swiper-slide.construction-slide .slide-info {
      min-width: 0;
      padding: 8px 12px; }
      .swiper-slide.construction-slide .slide-info .description {
        line-height: 1.2;
        height: 2.2em;
        overflow: hidden; }

.hoverable .hover-only {
  display: block; }

.hoverable:hover .hover-only {
  display: block; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8f8f8f;
  border-radius: 3px;
  background-clip: padding-box; }

/* Track */
::-webkit-scrollbar-track {
  background-color: #dbdbdb;
  border-radius: 3px;
  background-clip: padding-box; }

.leaflet-control-layers {
  border: none !important;
  border-radius: 8px; }
  .leaflet-control-layers .leaflet-control-layers-toggle {
    width: 40px !important;
    height: 40px !important; }

.leaflet-bar, .leaflet-control-layers {
  box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px !important; }

.leaflet-bar {
  border: none !important; }
  .leaflet-bar a {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important; }
  .leaflet-bar a:first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important; }
  .leaflet-bar a:last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important; }

.leaflet-headerbar {
  padding: 0 10px; }

.gm-style-iw, .leaflet-popup, .map-marker-popup {
  margin-bottom: 42px; }
  .gm-style-iw .gm-style-iw-d, .gm-style-iw .leaflet-popup-content-wrapper, .gm-style-iw .map-marker-popup-container, .leaflet-popup .gm-style-iw-d, .leaflet-popup .leaflet-popup-content-wrapper, .leaflet-popup .map-marker-popup-container, .map-marker-popup .gm-style-iw-d, .map-marker-popup .leaflet-popup-content-wrapper, .map-marker-popup .map-marker-popup-container {
    border-radius: 0;
    padding: 0;
    background-color: transparent; }
    .gm-style-iw .gm-style-iw-d > div, .gm-style-iw .gm-style-iw-d .leaflet-popup-content, .gm-style-iw .gm-style-iw-d .map-marker-popup-content, .gm-style-iw .leaflet-popup-content-wrapper > div, .gm-style-iw .leaflet-popup-content-wrapper .leaflet-popup-content, .gm-style-iw .leaflet-popup-content-wrapper .map-marker-popup-content, .gm-style-iw .map-marker-popup-container > div, .gm-style-iw .map-marker-popup-container .leaflet-popup-content, .gm-style-iw .map-marker-popup-container .map-marker-popup-content, .leaflet-popup .gm-style-iw-d > div, .leaflet-popup .gm-style-iw-d .leaflet-popup-content, .leaflet-popup .gm-style-iw-d .map-marker-popup-content, .leaflet-popup .leaflet-popup-content-wrapper > div, .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content, .leaflet-popup .leaflet-popup-content-wrapper .map-marker-popup-content, .leaflet-popup .map-marker-popup-container > div, .leaflet-popup .map-marker-popup-container .leaflet-popup-content, .leaflet-popup .map-marker-popup-container .map-marker-popup-content, .map-marker-popup .gm-style-iw-d > div, .map-marker-popup .gm-style-iw-d .leaflet-popup-content, .map-marker-popup .gm-style-iw-d .map-marker-popup-content, .map-marker-popup .leaflet-popup-content-wrapper > div, .map-marker-popup .leaflet-popup-content-wrapper .leaflet-popup-content, .map-marker-popup .leaflet-popup-content-wrapper .map-marker-popup-content, .map-marker-popup .map-marker-popup-container > div, .map-marker-popup .map-marker-popup-container .leaflet-popup-content, .map-marker-popup .map-marker-popup-container .map-marker-popup-content {
      margin: 0;
      width: 450px !important; }
    .gm-style-iw .gm-style-iw-d .popup-image-container, .gm-style-iw .leaflet-popup-content-wrapper .popup-image-container, .gm-style-iw .map-marker-popup-container .popup-image-container, .leaflet-popup .gm-style-iw-d .popup-image-container, .leaflet-popup .leaflet-popup-content-wrapper .popup-image-container, .leaflet-popup .map-marker-popup-container .popup-image-container, .map-marker-popup .gm-style-iw-d .popup-image-container, .map-marker-popup .leaflet-popup-content-wrapper .popup-image-container, .map-marker-popup .map-marker-popup-container .popup-image-container {
      height: 250px; }
      .gm-style-iw .gm-style-iw-d .popup-image-container img, .gm-style-iw .leaflet-popup-content-wrapper .popup-image-container img, .gm-style-iw .map-marker-popup-container .popup-image-container img, .leaflet-popup .gm-style-iw-d .popup-image-container img, .leaflet-popup .leaflet-popup-content-wrapper .popup-image-container img, .leaflet-popup .map-marker-popup-container .popup-image-container img, .map-marker-popup .gm-style-iw-d .popup-image-container img, .map-marker-popup .leaflet-popup-content-wrapper .popup-image-container img, .map-marker-popup .map-marker-popup-container .popup-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .gm-style-iw .gm-style-iw-d .popup-info, .gm-style-iw .gm-style-iw-d .leaflet-popup-tip, .gm-style-iw .leaflet-popup-content-wrapper .popup-info, .gm-style-iw .leaflet-popup-content-wrapper .leaflet-popup-tip, .gm-style-iw .map-marker-popup-container .popup-info, .gm-style-iw .map-marker-popup-container .leaflet-popup-tip, .leaflet-popup .gm-style-iw-d .popup-info, .leaflet-popup .gm-style-iw-d .leaflet-popup-tip, .leaflet-popup .leaflet-popup-content-wrapper .popup-info, .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-tip, .leaflet-popup .map-marker-popup-container .popup-info, .leaflet-popup .map-marker-popup-container .leaflet-popup-tip, .map-marker-popup .gm-style-iw-d .popup-info, .map-marker-popup .gm-style-iw-d .leaflet-popup-tip, .map-marker-popup .leaflet-popup-content-wrapper .popup-info, .map-marker-popup .leaflet-popup-content-wrapper .leaflet-popup-tip, .map-marker-popup .map-marker-popup-container .popup-info, .map-marker-popup .map-marker-popup-container .leaflet-popup-tip {
      color: white;
      background-color: rgba(115, 115, 115, 0.9); }
    .gm-style-iw .gm-style-iw-d .popup-description, .gm-style-iw .leaflet-popup-content-wrapper .popup-description, .gm-style-iw .map-marker-popup-container .popup-description, .leaflet-popup .gm-style-iw-d .popup-description, .leaflet-popup .leaflet-popup-content-wrapper .popup-description, .leaflet-popup .map-marker-popup-container .popup-description, .map-marker-popup .gm-style-iw-d .popup-description, .map-marker-popup .leaflet-popup-content-wrapper .popup-description, .map-marker-popup .map-marker-popup-container .popup-description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .gm-style-iw .leaflet-popup-tip-container, .leaflet-popup .leaflet-popup-tip-container, .map-marker-popup .leaflet-popup-tip-container {
    width: 56px !important;
    height: 35px !important;
    margin-left: -28px !important; }
    .gm-style-iw .leaflet-popup-tip-container .leaflet-popup-tip, .leaflet-popup .leaflet-popup-tip-container .leaflet-popup-tip, .map-marker-popup .leaflet-popup-tip-container .leaflet-popup-tip {
      height: 30px;
      width: 30px;
      background-color: rgba(115, 115, 115, 0.9);
      margin-top: -15px; }

.gm-style-iw {
  overflow: unset !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important; }
  .gm-style-iw .gm-style-iw-d {
    background-color: transparent !important;
    overflow: unset !important;
    max-height: none !important; }
    .gm-style-iw .gm-style-iw-d::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      z-index: 1;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      border-top: 20px solid rgba(115, 115, 115, 0.9);
      bottom: -19px;
      left: calc(50% - 20px); }

.gm-style-iw-t {
  bottom: 58px !important; }
  .gm-style-iw-t::after {
    display: none; }

@media only screen and (max-width: 1023px) and (max-width: 1023px) {
  .text-h2 {
    font-size: 32px; }
  .text-h3 {
    font-size: 32px; }
  .home-page .map {
    height: 550px !important; }
  .typology-carousel .q-carousel__slide.contain {
    background-color: #333333; }
  .home-constructions .home-constructions-block:first-child {
    margin-left: 1rem; }
  .home-constructions .home-constructions-block:last-child {
    margin-right: 1rem; }
  .typology {
    flex-direction: column; }
    .typology .typology-info {
      border: none;
      margin-left: 0 !important; }
  .construction-link {
    width: 100% !important; }
    .construction-link:not(:first-child) {
      margin: 0;
      margin-top: 10px; } }

@media only screen and (max-width: 1023px) {
  .results-container {
    width: 100% !important; }
  .leaflet-control-container .leaflet-top {
    margin-top: 50px; }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    padding: 0;
    background-color: transparent; }
    .leaflet-popup-content-wrapper .leaflet-popup-content {
      margin: 0;
      width: 400px !important;
      max-width: 90vw; }
    .leaflet-popup-content-wrapper .popup-image-container {
      height: 200px; }
    .leaflet-popup-content-wrapper .popup-info {
      color: white;
      background-color: rgba(115, 115, 115, 0.9); }
    .leaflet-popup-content-wrapper .leaflet-control.full-width {
      width: 100vw !important; } }

@media print {
  button {
    display: none; } }

.q-carousel__control button {
  background-color: #616161;
  opacity: 0.7; }
