.leaflet-control-container {
  .leaflet-top {
    margin-top: 50px;
  }
}
.leaflet-popup-content-wrapper {
  border-radius: 0;
  padding: 0;
  background-color: transparent;

  .leaflet-popup-content {
    margin: 0;
    width: 400px!important;
    max-width: 90vw;
  }
  .popup-image-container {
    height: 200px;
  }
  .popup-info {
    color: white;
    background-color: rgba(45%, 45%, 45%, .9);
  }
  .leaflet-control.full-width {
    width: 100vw!important;
  }
}