@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
@import './variables.styl'

str-fr(selector, name, i = '')
  unquote(replace(unquote('<i>'), unquote(i), replace(unquote('<name>'), unquote(name), unquote(selector))))

str-fe(selector, name, noProc, i = '')
  if noProc
    return str-fr(selector, name, i)
  return unquote(join(',', str-fr(selector, '', i) str-fr(selector, name, i)))

fg($name, $size)
  $noProcZero = $size == 0
  $noProcNotZero = $size > 0
  @media (min-width $size)
    {str-fe('.flex<name>-', $name, $noProcNotZero)}
      &block
        display: block
      &inline
        display: inline-block

    for $space, $value in $spaces
      .q-pa{$name}-{$space}
        padding: $value.y $value.x
      .q-pl{$name}-{$space}
        padding-left: $value.x
      .q-pr{$name}-{$space}
        padding-right: $value.x
      .q-pt{$name}-{$space}
        padding-top: $value.y
      .q-pb{$name}-{$space}
        padding-bottom: $value.y
      .q-px{$name}-{$space}
        @extends .q-pl{$name}-{$space}, .q-pr{$name}-{$space}
      .q-py{$name}-{$space}
        @extends .q-pt{$name}-{$space}, .q-pb{$name}-{$space}
      .q-ma{$name}-{$space}
        margin: $value.y $value.x
      .q-ml{$name}-{$space}
        margin-left: $value.x
      .q-mr{$name}-{$space}
        margin-right: $value.x
      .q-mt{$name}-{$space}
        margin-top: $value.y
      .q-mb{$name}-{$space}
        margin-bottom: $value.y
      .q-mx{$name}-{$space}
        @extends .q-ml{$name}-{$space}, .q-mr{$name}-{$space}
      .q-my{$name}-{$space}
        @extends .q-mt{$name}-{$space}, .q-mb{$name}-{$space}

    .q-ml{$name}-auto
      margin-left: auto
    .q-mr{$name}-auto
      margin-right: auto
    .q-mx{$name}-auto
      @extends .q-ml{$name}-auto, .q-mr{$name}-auto

    .row, .column, .flex
      if $noProcNotZero
        {str-fr('&.inline<name>', $name)}
          display: inline-flex
      {str-fr('&<name>', $name)}
        display: flex
        flex-wrap: wrap
        {str-fe('&.inline<name>', $name, $noProcZero)}
          display: inline-flex

    .row
      if $noProcNotZero
        {str-fr('&.reverse<name>', $name)}
          flex-direction: row-reverse
      {str-fr('&<name>', $name)}
        flex-direction: row
        {str-fe('&.reverse<name>', $name, $noProcZero)}
          flex-direction: row-reverse

    .column
      if $noProcNotZero
        {str-fr('&.reverse<name>', $name)}
          flex-direction: column-reverse
      {str-fr('&<name>', $name)}
        flex-direction: column
        {str-fe('&.reverse<name>', $name, $noProcZero)}
          flex-direction: column-reverse

    {str-fr('.wrap<name>', $name)}
      flex-wrap: wrap
    {str-fr('.no-wrap<name>', $name)}
      flex-wrap: nowrap
    {str-fr('.reverse-wrap<name>', $name)}
      flex-wrap: wrap-reverse

    {str-fr('.order<name>-', $name)}
      &first
        order: -10000
      &last
        order: 10000
      &none
        order: 0

    {str-fr('.justify<name>-', $name)}
      &start
        justify-content: flex-start
      &end
        justify-content: flex-end
      &center
        justify-content: center
      &between
        justify-content: space-between
      &around
        justify-content: space-around
      &evenly
        justify-content: space-evenly

    {str-fr('.items<name>-', $name)}
      &start
        align-items: flex-start
      &end
        align-items: flex-end
      &center
        align-items: center
      &baseline
        align-items: baseline
      &stretch
        align-items: stretch

    {str-fr('.content<name>-', $name)}
      &start
        align-content: flex-start
      &end
        align-content: flex-end
      &center
        align-content: center
      &between
        align-content: space-between
      &around
        align-content: space-around

    {str-fr('.self<name>-', $name)}
      &start
        align-self: flex-start
      &end
        align-self: flex-end
      &center
        align-self: center
      &baseline
        align-self: baseline
      &stretch
        align-self: stretch

    {str-fr('.flex<name>-center', $name)}
      @extends .items{$name}-center
      @extends .justify{$name}-center

    for $gname, $gsize in $flex-gutter
      {str-fr('.q-gutter<name>', $name)}
        &-x-{$gname}
          margin-left: (- $gsize)
          > *
            margin-left: $gsize
        &-y-{$gname}
          margin-top: (- $gsize)
          > *
            margin-top: $gsize
        &-{$gname}
          @extends .q-gutter{$name}-x-{$gname}, .q-gutter{$name}-y-{$gname}
      {str-fr('.q-col-gutter<name>', $name)}
        &-x-{$gname}
          margin-left: (- $gsize)
          > *
            padding-left: ($gsize)
        &-y-{$gname}
          margin-top: (- $gsize)
          > *
            padding-top: ($gsize)
        &-{$gname}
          @extends .q-col-gutter{$name}-x-{$gname}, .q-col-gutter{$name}-y-{$gname}

    for $name2, $size2 in $sizes
      if $size >= $size2
        $name2c = s('-%s', unquote($name2))
        $noProcNotZero2 = $size2 > 0

        for $i in (0..$flex-cols)
          $ic = s('%s', $i)
          $internal-size = (round($i / $flex-cols * 100, 4))%

          {str-fr('.row<name>', $name)}
            {str-fe('> .col<name>-<i>', $name2c, $noProcNotZero2, $ic)}
              height: auto
              width: $internal-size
            {str-fe('> .offset<name>-<i>', $name2c, $noProcNotZero2, $ic)}
              margin-left: $internal-size

          {str-fr('.column<name>', $name)}
            {str-fe('> .col<name>-<i>', $name2c, $noProcNotZero2, $ic)}
              height: $internal-size
              width: auto

for $name, $size in $sizes
  fg(s('-%s', unquote($name)), $size)
