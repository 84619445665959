@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import "~leaflet/dist/leaflet.css";

@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";

@import "./variables";

// Custom fonts
@import "fonts";

// Custom scss
@import "./desktop/generals";
@import "./desktop/leaflet";

// Custom scss mobile
@media only screen and (max-width: 1023px) {
  @import "./mobile/generals";
  @import "./mobile/leaflet";
}

// Custom scss prints
@media print {
  @import './print/generals';
}

.q-carousel__control button {
  background-color: $grey-8;
  opacity: 0.7;
}