
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$darkgray: #333333;
$brand: #EB6909;
$black: #000;
$blue: #2364AA;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #FF0039;
//$yellow: #FF7518;
//$orange: #f0ad4e;
$yellow: #f0ad4e;
$orange: #FF7518;
$green: #27AE60;
$teal: #20c997;
$cyan: #9954BB;
$primary: $blue;
$secondary: $gray-800;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$enable-rounded: false;
$body-color: $gray-800;
$font-family-sans-serif: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
$font-size-base: 0.9375rem;
$headings-font-weight: 300;
$navbar-dark-hover-color: rgba($white,1);
$navbar-light-hover-color: rgba($black,.9);
$alert-border-width: 0;
$progress-height: 0.5rem;

h4 {
    font-size: 2rem
}

h6,
.text-h6 {
    font-size: 1.1rem
}