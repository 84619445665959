@media only screen and (max-width: 1023px) {
  .text-h2 {
    font-size: 32px;
  }
  .text-h3 {
    font-size: 32px;
  }
  .home-page {
    .map {
      height: 550px!important;
    }
  }
  .typology-carousel .q-carousel__slide.contain {
    background-color: #333333;
  }
  .home-constructions {
    .home-constructions-block {
      &:first-child {
        margin-left: 1rem;
      }
      &:last-child {
        margin-right: 1rem;
      }
    }
  }
  .typology {
    flex-direction: column;
    .typology-info {
      border: none;
      margin-left: 0!important;
    }
  }
  .construction-link {
    width: 100% !important;
    &:not(:first-child) {
      margin: 0;
      margin-top: 10px;
    }
  }
}

.results-container {
  width: 100% !important;
}